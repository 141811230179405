export const CampaignChannelEnum = Object.freeze({
  DISPLAY_SEARCH: { label: 'Display/Search', value: 'displaySearch' },
  OFFLINE: { label: 'Offline', value: 'offline' },
  REVIEW: { label: 'Review', value: 'reviews' },
  CRM: { label: 'CRM', value: 'crm' },
  SOCIAL_MEDIA: { label: 'Social Media', value: 'socialMedia' },
});

const CampaignCompareChannelEnum = Object.freeze({
  DISPLAY_SEARCH: { label: 'Display/Search', value: 'displaySearch' },
  DISPLAY_SEARCH_COMPARE: {
    label: 'Display/Search',
    value: 'displaySearchCompare',
  },
  OFFLINE: { label: 'Offline', value: 'offline' },
  OFFLINE_COMPARE: { label: 'Offline', value: 'offlineCompare' },
  REVIEW: { label: 'Review', value: 'reviews' },
  REVIEW_COMPARE: { label: 'Review', value: 'reviewsCompare' },
  CRM: { label: 'CRM', value: 'crm' },
  CRM_COMPARE: { label: 'CRM', value: 'crmCompare' },
  SOCIAL_MEDIA: { label: 'Social Media', value: 'socialMedia' },
  SOCIAL_MEDIA_COMPARE: { label: 'Social Media', value: 'socialMediaCompare' },
});

export const campaignChannelsList = [...Object.values(CampaignChannelEnum)];

export const campaignCompareChannelsList = [
  ...Object.values(CampaignCompareChannelEnum),
];

export const campaignChannelNames = campaignChannelsList.map(
  ({ label }) => label
);

export const campaignChannelValues = campaignChannelsList.map(
  ({ value }) => value
);

export const campaignCompareChannelValues = campaignCompareChannelsList.map(
  ({ value }) => value
);
