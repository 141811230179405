<template>
  <vx-card>
    <div
      class="
        vx-row
        flex-col-reverse
        md:flex-col-reverse
        sm:flex-row
        lg:flex-row
      "
    >
      <div class="vx-col w-full flex flex-col">
        <h4 class="text-center mb-8">{{ title }}</h4>
        <vue-apex-charts
          v-if="chartData.series"
          :ref="id"
          type="bar"
          height="200"
          :options="currentChartData.options"
          :series="chartData.series"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

import {
  barChartOptions,
  barChartColors,
  barChartColorsCompare,
} from '@/constants';

import MathUtil from '@/utils/MathUtil';

export default {
  components: {
    VueApexCharts,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
    unit: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    compare: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentChartData: {
        options: {
          ...barChartOptions,
          colors: this.compare ? barChartColorsCompare : barChartColors,
          chart: {
            id: this.id,
          },
          yaxis: {
            labels: {
              formatter: (value) => `${MathUtil.round(value)}${this.unit}`,
            },
          },
        },
      },
    };
  },
  computed: {
    categoriesWithValues() {
      const values = this.chartData.series[0].data;

      return this.chartData.categories.map((category, idx) => {
        const result = MathUtil.round(values[idx]);

        return [...category, `${result}${this.unit}`];
      });
    },
  },
  watch: {
    chartData: {
      deep: true,
      handler(data) {
        this.updateChart(data);
      },
    },
  },
  created() {
    this.currentChartData.options.xaxis.categories = this.categoriesWithValues;
  },
  methods: {
    updateChart(data) {
      this.$refs[this.id].updateSeries([
        {
          data: data.series[0].data,
        },
      ]);

      this.$refs[this.id].updateOptions({
        xaxis: {
          categories: this.categoriesWithValues,
        },
        colors: this.compare ? barChartColorsCompare : barChartColors,
      });
    },
  },
};
</script>
