import axios from '@/axios';
import { RetailerAction } from '@/store/actionTypes';
import { RetailerMutation } from '@/store/mutationTypes';

export default {
  [RetailerAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post('/retailers', item)
        .then(({ data }) => {
          commit(RetailerMutation.ADD, Object.assign(item, { id: data.id }));
          resolve(data);
        })
        .catch(reject);
    });
  },
  [RetailerAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get('/retailers')
        .then(({ data }) => {
          commit(RetailerMutation.SET, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [RetailerAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/retailers/${item.id}`, item)
        .then(({ data }) => {
          commit(RetailerMutation.UPDATE, data);
          resolve(data);
        })
        .catch(reject);
    });
  },
  [RetailerAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/retailers/${id}`)
        .then(({ data }) => {
          commit(RetailerMutation.REMOVE, id);
          resolve(data);
        })
        .catch(reject);
    });
  },
};
