<template>
  <div id="dashboard-analytics">
    <h2 class="mb-8">Campaigns</h2>

    <vx-card ref="filterCard" title="Filters" class="mt-6 mb-8" collapse-action>
      <div class="vx-row flex mt-4">
        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Region" />
          <v-select
            v-model="filters.region"
            :clearable="false"
            :options="filterRegionList"
            class="bg-white w-full cursor-pointer"
            @input="fetchData"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Country" />
          <v-select
            v-model="filters.country"
            :clearable="false"
            :options="filterCountryList"
            class="bg-white w-full cursor-pointer"
            @input="fetchData"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Status" />
          <v-select
            v-model="filters.status"
            :clearable="false"
            :options="filterStatusList"
            class="bg-white w-full cursor-pointer"
            @input="fetchData"
          />
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Start date" />
          <div class="relative">
            <datepicker
              v-model="filters.startDate"
              placeholder="Select Date"
              class="w-full"
              @input="fetchData"
            />
            <feather-icon
              icon="XIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="datepicker-reset"
              @click="resetDate('startDate')"
            />
          </div>
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="End date" />
          <div class="relative">
            <datepicker
              v-model="filters.endDate"
              placeholder="Select Date"
              class="w-full"
              @input="fetchData"
            />
            <feather-icon
              icon="XIcon"
              svg-classes="w-5 h-5 hover:text-danger stroke-current"
              class="datepicker-reset"
              @click="resetDate('endDate')"
            />
          </div>
        </div>

        <div class="vx-col lg:w-1/6 md:w-1/3 sm:w-1/2 w-full">
          <vs-label text="Retailer" />
          <v-select
            v-model="filters.retailer_id"
            :clearable="false"
            :options="filterRetailerList"
            class="bg-white w-full cursor-pointer"
            @input="fetchData"
          />
        </div>

        <div class="vx-col lg:w-1/1 md:w-1/2 sm:w-1/2 w-full mt-2">
          <vs-label text="Campaigns by name" />
          <multiselect
            v-model="filters.campaignsByName"
            :options="filterCampaignListByName"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Campaigns by name"
            label="label"
            track-by="value"
            :show-labels="false"
            @select="fetchData"
            @remove="fetchData"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} campaigns selected
              </span>
            </template>
          </multiselect>
          <button
            v-if="filters.campaignsByName.length"
            class="
              vs-component vs-button
              mt-2
              vs-button-danger vs-button-border
            "
            @click="clearAllByName"
          >
            Clear all filters by name
          </button>
        </div>

        <div class="vx-col lg:w-1/1 md:w-1/2 sm:w-1/2 w-full mt-2">
          <vs-label text="Campaigns by global ID" />
          <multiselect
            v-model="filters.campaignsByGlobalId"
            :options="filterCampaignListByGlobalId"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            placeholder="Select Campaigns by global ID"
            label="label"
            track-by="value"
            :show-labels="false"
            @select="fetchData"
            @remove="fetchData"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span v-if="values.length && !isOpen" class="multiselect__single">
                {{ values.length }} campaigns selected
              </span>
            </template>
          </multiselect>
          <button
            v-if="filters.campaignsByGlobalId.length"
            class="
              vs-component vs-button
              mt-2
              vs-button-danger vs-button-border
            "
            @click="clearAllById"
          >
            Clear all filters by global ID
          </button>
        </div>

        <div class="vx-col lg:w-1/1 md:w-1/4 sm:w-1/4 xxl:w-1/5 mt-2">
          <div class="mt-4 flex">
            <vs-checkbox
              v-model="filters.top10Roi"
              name="top_10_roi"
              @change="fetchData"
            />
            <vs-label text="Filter on Top 10 ROI" />
          </div>
        </div>

        <div class="vx-col lg:w-1/1 md:w-1/4 sm:w-1/4 xxl:w-1/5 w-full mt-2">
          <div class="mt-4 flex">
            <vs-checkbox
              v-model="filters.negativeTrends"
              name="negative_trends"
              @change="fetchData"
            />
            <vs-label text="Filter out negative trends" />
          </div>
        </div>

        <div class="vx-col w-full mt-4">
          <div class="vx-row">
            <div class="vx-col w-auto">
              <div class="mt-2 flex items-center h-100">
                <vs-checkbox
                  v-model="filters.shouldCompare"
                  name="top_10_roi"
                  @change="fetchData"
                />
                <vs-label text="Compare periods" />
              </div>
            </div>
            <div class="vx-col lg:w-1/3 w-full">
              <vs-label text="Period 1" />
              <div class="flex">
                <div class="relative mr-2">
                  <datepicker
                    v-model="filters.compareStartP1"
                    placeholder="Select Date"
                    class="w-full"
                    @input="setCompareDate"
                  />
                  <feather-icon
                    icon="XIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    class="datepicker-reset"
                    @click="resetDate('compareStartP1')"
                  />
                </div>
                <div class="relative">
                  <datepicker
                    v-model="filters.compareEndP1"
                    placeholder="Select Date"
                    class="w-full"
                    @input="setCompareDate"
                  />
                  <feather-icon
                    icon="XIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    class="datepicker-reset"
                    @click="resetDate('compareEndP1')"
                  />
                </div>
              </div>
            </div>
            <div class="vx-col lg:w-1/3 w-full">
              <vs-label text="Period 2" />
              <div class="flex">
                <div class="relative mr-2">
                  <datepicker
                    v-model="filters.compareStartP2"
                    placeholder="Select Date"
                    class="w-full"
                    @input="setCompareDate"
                  />
                  <feather-icon
                    icon="XIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    class="datepicker-reset"
                    @click="resetDate('compareStartP2')"
                  />
                </div>
                <div class="relative">
                  <datepicker
                    v-model="filters.compareEndP2"
                    placeholder="Select Date"
                    class="w-full"
                    @input="setCompareDate"
                  />
                  <feather-icon
                    icon="XIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    class="datepicker-reset"
                    @click="resetDate('compareEndP2')"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vx-card>

    <div class="vx-row">
      <div class="vx-col w-full md:w-1/2 xxl:w-1/3">
        <bar-chart
          v-if="chartsData.displaySearchRevenue.series[0].data"
          id="displaySearchRevenue"
          class="h-full"
          title="Display/Search: Revenue"
          :chart-data="chartsData.displaySearchRevenue"
          :unit="$store.state.currencySign"
          :compare="ableToCompare"
        />
      </div>

      <div class="vx-col mt-8 w-full md:w-1/2 md:mt-0 xxl:w-1/3">
        <bar-chart
          v-if="chartsData.displaySearchUnits.series[0].data"
          id="displaySearchUnits"
          class="h-full"
          title="Display/Search: Units"
          :chart-data="chartsData.displaySearchUnits"
          :compare="ableToCompare"
        />
      </div>

      <div class="vx-col mt-8 w-full md:w-1/2 xxl:w-1/3 xxl:mt-0">
        <bar-chart
          v-if="chartsData.displayRoi.series[0].data"
          id="displayRoiChart"
          class="h-full"
          title="Display/Search: Planned ROI & ROI"
          unit="%"
          :chart-data="chartsData.displayRoi"
          :compare="ableToCompare"
        />
      </div>

      <div class="vx-col mt-8 w-full md:w-1/2 xxl:w-1/3">
        <bar-chart
          v-if="chartsData.roiPerChannel.series[0].data"
          id="displayRoiPerChannel"
          class="h-full"
          :chart-data="chartsData.roiPerChannel"
          unit="%"
          title="ROI per channel"
          :compare="ableToCompare"
        />
      </div>

      <div class="vx-col mt-8 w-full md:w-1/2 xxl:w-1/3">
        <bar-chart
          v-if="chartsData.totalRevenuePerChannel.series[0].data"
          id="displayTotalRevenuPerChannel"
          class="h-full"
          title="Total Revenue per channel"
          :chart-data="chartsData.totalRevenuePerChannel"
          :unit="$store.state.currencySign"
          :compare="ableToCompare"
        />
      </div>

      <div class="vx-col mt-8 w-full md:w-1/2 xxl:w-1/3">
        <bar-chart
          v-if="chartsData.totalSoldPerActivity.series[0].data"
          id="totalSoldPerACtivity"
          class="h-full"
          :chart-data="chartsData.totalSoldPerActivity"
          title="Total Sold per channel"
          :compare="ableToCompare"
        />
      </div>

      <div v-if="topRoiList.length" class="vx-col mt-8 w-full lg:w-1/2">
        <vx-card title="Top 10 ROI Campaigns">
          <div slot="no-body" class="mt-4">
            <vs-table :data="topRoiList" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>NAME</vs-th>
                <vs-th>ROI (%)</vs-th>
                <vs-th>ACTIONS</vs-th>
              </template>

              <template slot-scope="">
                <vs-tr v-for="campaign in topRoiList" :key="campaign.id">
                  <vs-td :data="campaign.id">
                    <span>{{ campaign.name }}</span>
                  </vs-td>
                  <vs-td :data="campaign.id">
                    <span>{{ campaign.roi }}%</span>
                  </vs-td>
                  <vs-td :data="campaign.id">
                    <router-link :to="`/campaigns/${campaign.id}`">
                      Show
                    </router-link>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>

      <div v-if="topRoasList.length" class="vx-col mt-8 w-full lg:w-1/2">
        <vx-card title="Top 10 ROAS Campaigns">
          <div slot="no-body" class="mt-4">
            <vs-table :data="topRoasList" class="table-dark-inverted">
              <template slot="thead">
                <vs-th>NAME</vs-th>
                <vs-th>ROAS (%)</vs-th>
                <vs-th>ACTIONS</vs-th>
              </template>

              <template slot-scope="">
                <vs-tr v-for="campaign in topRoasList" :key="campaign.id">
                  <vs-td :data="campaign.id">
                    <span>{{ campaign.name }}</span>
                  </vs-td>
                  <vs-td :data="campaign.id">
                    <span>{{ campaign.roas }}%</span>
                  </vs-td>
                  <vs-td :data="campaign.id">
                    <router-link :to="`/campaigns/${campaign.id}`">
                      Show
                    </router-link>
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Datepicker from 'vuejs-datepicker';
import Multiselect from 'vue-multiselect';
import vSelect from 'vue-select';

import {
  CampaignAction,
  DashboardAction,
  RetailerAction,
} from '@/store/actionTypes';
import moduleRetailer from '@/store/retailers/moduleRetailer';
import moduleCampaign from '@/store/campaigns/moduleCampaign';
import { countryList } from '@/enums/CountryEnum';
import {
  campaignChannelValues,
  campaignCompareChannelValues,
} from '@/enums/CampaignChannelEnum';
import { campaignStatusList } from '@/enums/CampaignStatusEnum';
import { regionList } from '@/enums/RegionEnum';

import useCurrencyMixin from '@/mixins/useCurrencyMixin';

import BarChart from '@/views/ui-elements/BarChart.vue';

export default {
  components: {
    BarChart,
    Datepicker,
    Multiselect,
    vSelect,
  },
  mixins: [useCurrencyMixin],
  data() {
    return {
      chartsData: {
        displaySearchRevenue: {
          categories: [['Budget revenue'], ['Revenue']],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        displaySearchUnits: {
          categories: [['Units budget'], ['Units sold']],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        displayRoi: {
          categories: [['Planned ROI'], ['ROI']],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        roiPerChannel: {
          categories: [
            ['Display/search'],
            ['Offline'],
            ['Review'],
            ['CRM'],
            ['Social Media'],
          ],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        totalRevenuePerChannel: {
          categories: [
            ['Display/search'],
            ['Offline'],
            ['Review'],
            ['CRM'],
            ['Social Media'],
          ],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
        totalSoldPerActivity: {
          categories: [
            ['Display/search'],
            ['Offline'],
            ['Review'],
            ['CRM'],
            ['Social Media'],
          ],
          series: [
            {
              name: 'Value',
              data: null,
            },
          ],
        },
      },

      topRoiList: [],
      topRoasList: [],

      // Filters
      filters: {
        campaignsByGlobalId: [],
        campaignsByName: [],
        country: { label: 'All', value: null },
        endDate: null,
        region: { label: 'All', value: null },
        retailer_id: { label: 'All', value: null },
        startDate: null,
        status: { label: 'All', value: null },
        top10Roi: false,
        negativeTrends: false,
        shouldCompare: false,
        compareStartP1: null,
        compareEndP1: null,
        compareStartP2: null,
        compareEndP2: null,
      },
    };
  },
  computed: {
    ...mapState('retailer', {
      retailers: ({ retailers }) => retailers,
    }),
    ...mapState('campaign', {
      campaigns: ({ campaigns }) => campaigns,
    }),

    retailerList() {
      return this.retailers.map(({ id, name }) => ({ value: id, label: name }));
    },
    campaignListByGlobalId() {
      return this.campaigns
        .filter((campaign) => campaign.unique_id !== null)
        .map(({ id, unique_id }) => ({ value: id, label: unique_id }));
    },
    campaignListByName() {
      return this.campaigns.map(({ id, name }) => ({ value: id, label: name }));
    },
    filterRegionList() {
      return [{ label: 'All', value: null }, ...regionList];
    },
    filterCountryList() {
      return [{ label: 'All', value: null }, ...countryList];
    },
    filterStatusList() {
      return [{ label: 'All', value: null }, ...campaignStatusList];
    },
    filterRetailerList() {
      return [{ label: 'All', value: null }, ...this.retailerList];
    },
    filterCampaignListByGlobalId() {
      return [...this.campaignListByGlobalId];
    },
    filterCampaignListByName() {
      return [...this.campaignListByName];
    },
    hasFilledAllCompareDates() {
      return (
        Boolean(this.filters.compareStartP1) &&
        Boolean(this.filters.compareEndP1) &&
        Boolean(this.filters.compareStartP2) &&
        Boolean(this.filters.compareEndP2)
      );
    },
    ableToCompare() {
      return this.filters.shouldCompare && this.hasFilledAllCompareDates;
    },
  },
  methods: {
    ...mapActions('campaign', [CampaignAction.fetch]),
    ...mapActions('dashboard', [
      DashboardAction.fetchStatistics,
      DashboardAction.fetchTopRoiList,
      DashboardAction.fetchTopRoasList,
    ]),
    ...mapActions('retailer', [RetailerAction.fetch]),

    registerStoreModules() {
      if (!moduleRetailer.isRegistered) {
        this.$store.registerModule('retailer', moduleRetailer);
        moduleRetailer.isRegistered = true;
      }
      if (!moduleCampaign.isRegistered) {
        this.$store.registerModule('campaign', moduleCampaign);
        moduleCampaign.isRegistered = true;
      }
    },
    clearAllByName() {
      this.filters.campaignsByName = [];
      this.fetchData();
    },
    clearAllById() {
      this.filters.campaignsByGlobalId = [];
      this.fetchData();
    },
    resetDate(date) {
      this.filters[date] = null;
      this.fetchData();
    },
    createFilterQuery() {
      const filters = {
        region: this.filters.region.value,
        country: this.filters.country.value,
        status: this.filters.status.value,
        start_date: this.filters.startDate
          ? this.filters.startDate.getTime()
          : this.filters.startDate,
        end_date: this.filters.endDate
          ? this.filters.endDate.getTime()
          : this.filters.endDate,
        retailer_id: this.filters.retailer_id.value,
        campaigns_by_name: this.filters.campaignsByName.map(
          ({ value }) => value
        ),
        campaigns_by_global_id: this.filters.campaignsByGlobalId.map(
          ({ value }) => value
        ),
        top_10_roi: this.filters.top10Roi,
        negative_trends: this.filters.negativeTrends,
        should_compare: this.filters.shouldCompare,
        compare_start_p1: this.filters.compareStartP1
          ? this.filters.compareStartP1.getTime()
          : this.filters.compareStartP1,
        compare_end_p1: this.filters.compareEndP1
          ? this.filters.compareEndP1.getTime()
          : this.filters.compareEndP1,
        compare_start_p2: this.filters.compareStartP2
          ? this.filters.compareStartP2.getTime()
          : this.filters.compareStartP2,
        compare_end_p2: this.filters.compareEndP2
          ? this.filters.compareEndP2.getTime()
          : this.filters.compareEndP2,
      };

      return filters;
    },
    setChartsData(response) {
      const [statistics, topRoiList, topRoasList] = response;

      const { currencyRate } = this.$store.state;
      const {
        planned,
        result,
        roiPerChannel,
        totalRevenuePerChannel,
        totalSoldPerActivity,
        compared,
      } = statistics;

      const displaySearchRevenue = compared
        ? [
            planned.budget_revenue * currencyRate,
            compared.planned.budget_revenue * currencyRate,
            result.revenue * currencyRate,
            compared.result.revenue * currencyRate,
          ]
        : [
            planned.budget_revenue * currencyRate,
            result.revenue * currencyRate,
          ];
      const displaySearchUnits = compared
        ? [
            planned.units_budget,
            compared.planned.units_budget,
            result.units_sold,
            compared.result.units_sold,
          ]
        : [planned.units_budget, result.units_sold];
      const displayRoiData = compared
        ? [planned.roi, compared.planned.roi, result.roi, compared.result.roi]
        : [planned.roi, result.roi];

      /* eslint-disable */
      const roiPerChannelData = compared
        ? campaignCompareChannelValues.map((channel) => {
            return channel.includes('Compare')
              ? compared.roiPerChannel[channel.replace('Compare', '')]
              : roiPerChannel[channel];
          })
        : campaignChannelValues.map((channel) => roiPerChannel[channel]);

      const totalRevenuePerChannelData = compared
        ? campaignCompareChannelValues.map((channel) => {
            return channel.includes('Compare')
              ? compared.totalRevenuePerChannel[
                  channel.replace('Compare', '')
                ] * currencyRate
              : totalRevenuePerChannel[channel] * currencyRate;
          })
        : campaignChannelValues.map(
            (channel) => totalRevenuePerChannel[channel] * currencyRate
          );

      const totalSoldPerActivityData = compared
        ? campaignCompareChannelValues.map((channel) => {
            return channel.includes('Compare')
              ? compared.totalSoldPerActivity[channel.replace('Compare', '')]
              : totalSoldPerActivity[channel];
          })
        : campaignChannelValues.map((channel) => totalSoldPerActivity[channel]);
      /* eslint-enable */

      this.chartsData.displaySearchRevenue.series[0].data =
        displaySearchRevenue;
      this.chartsData.displaySearchUnits.series[0].data = displaySearchUnits;
      this.chartsData.displayRoi.series[0].data = displayRoiData;
      this.chartsData.roiPerChannel.series[0].data = roiPerChannelData;
      this.chartsData.totalRevenuePerChannel.series[0].data =
        totalRevenuePerChannelData;
      this.chartsData.totalSoldPerActivity.series[0].data =
        totalSoldPerActivityData;

      this.topRoiList = topRoiList;
      this.topRoasList = topRoasList;
    },
    updateChartCategories() {
      const displaySearchRevenueCategories = this.ableToCompare
        ? [
            [['Period 1'], ['Budget revenue']],
            [['Period 2'], ['Budget revenue']],
            [['Period 1'], ['Revenue']],
            [['Period 2'], ['Revenue']],
          ]
        : [['Budget revenue'], ['Revenue']];
      const displaySearchUnitsCategories = this.ableToCompare
        ? [
            [['Period 1'], ['Units budget']],
            [['Period 2'], ['Units budget']],
            [['Period 1'], ['Units sold']],
            [['Period 2'], ['Units sold']],
          ]
        : [['Units budget'], ['Units sold']];
      const displayRoiCategories = this.ableToCompare
        ? [
            [['Period 1'], ['Planned ROI']],
            [['Period 2'], ['Planned ROI']],
            [['Period 1'], ['ROI']],
            [['Period 2'], ['ROI']],
          ]
        : [['Planned ROI'], ['ROI']];
      const channelCategories = this.ableToCompare
        ? [
            [['Display/'], ['search']],
            [['Display/'], ['search']],
            ['Offline'],
            ['Offline'],
            ['Review'],
            ['Review'],
            ['CRM'],
            ['CRM'],
            [['Social'], ['Media']],
            [['Social'], ['Media']],
          ]
        : [
            [['Display/'], ['search']],
            ['Offline'],
            ['Review'],
            ['CRM'],
            [['Social'], ['Media']],
          ];

      this.$set(
        this.chartsData.displaySearchRevenue,
        'categories',
        displaySearchRevenueCategories
      );
      this.$set(
        this.chartsData.displaySearchUnits,
        'categories',
        displaySearchUnitsCategories
      );
      this.$set(this.chartsData.displayRoi, 'categories', displayRoiCategories);
      this.$set(this.chartsData.roiPerChannel, 'categories', channelCategories);
      this.$set(
        this.chartsData.totalRevenuePerChannel,
        'categories',
        channelCategories
      );
      this.$set(
        this.chartsData.totalSoldPerActivity,
        'categories',
        channelCategories
      );
    },
    setCompareDate() {
      if (this.filters.shouldCompare && this.hasFilledAllCompareDates) {
        this.fetchData();
      }
    },
    async fetchData() {
      this.$vs.loading();

      try {
        const response = await Promise.all([
          this.fetchStatistics(this.createFilterQuery()),
          this.fetchTopRoiList(),
          this.fetchTopRoasList(),
        ]);

        this.updateChartCategories();
        this.setChartsData(response);
      } catch (error) {
        this.notifyError(error, 'Problem with loading statistics.');
      }

      this.$vs.loading.close();
    },
  },
  watch: {
    '$store.state.currency'() {
      this.fetchData();
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      await Promise.all([
        this.fetchCampaigns(),
        this.fetchData(),
        this.fetchRetailers(),
      ]);
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
#dashboard-analytics {
  .greet-user {
    position: relative;

    .decore-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    .decore-right {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  @media (max-width: 576px) {
    .decore-left,
    .decore-right {
      width: 140px;
    }
  }

  .datepicker-reset {
    position: absolute !important;
    top: 10px;
    right: 4px;
    cursor: pointer;
    z-index: 2;
  }

  .multiselect__placeholder {
    color: #626262;
  }

  .multiselect__tags {
    border: 1px solid rgba(60, 60, 60, 0.26);

    & > span {
      font-size: 1rem;
      letter-spacing: 0.01rem;
    }
  }
}
</style>
