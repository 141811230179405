import { colors } from '@/../themeConfig';
import MathUtil from '@/utils/MathUtil';

export const barChartColors = [
  colors.dark,
  colors.danger,
  colors.warning,
  colors.purple,
  colors.primary,
];

export const barChartColorsCompare = [
  colors.dark,
  colors.dark,
  colors.danger,
  colors.danger,
  colors.warning,
  colors.warning,
  colors.purple,
  colors.purple,
  colors.primary,
  colors.primary,
];

export const barChartOptions = {
  grid: {
    show: false,
    padding: {
      left: 0,
      right: 0,
    },
  },
  chart: {
    type: 'bar',
    toolbar: { show: false },
  },
  legend: {
    show: false,
  },
  yaxis: {
    tickAmount: 10,
  },
  xaxis: {
    categories: [],
    labels: {
      rotate: 0,
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  tooltip: {
    x: { show: false },
  },
  dataLabels: {
    formatter: function (value, { seriesIndex, dataPointIndex, w }) {
      return MathUtil.round(value);
    },
  },
};

export const pieChartColors = [
  colors.dark,
  colors.danger,
  colors.warning,
  colors.purple,
  colors.primary,
];
